import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  // TODO: [CAD-1461] Enable React Strict Mode
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
